h1 {
  font-family: "Quicksand", sans-serif;
  font-size: 4rem; /* 64px */
}

h2 {
  font-family: "Quicksand", sans-serif;
  font-size: 3rem; /* 48px */
}

h3 {
  font-family: "Quicksand", sans-serif;
  font-size: 2.5rem; /* 40px */
}

h4 {
  font-family: "Quicksand", sans-serif;
  font-size: 2rem; /* 32px */
}

h4.med-weight {
  font-size: 2rem;
  font-weight: 600;
}

h5 {
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem; /* 24px */
}

h5.med-weight {
  font-size: 1.5rem;
  font-weight: 600;
}

h6 {
  font-family: "Quicksand", sans-serif;
  font-size: 1.25rem; /* 20px */
}

h6.med-weight {
  font-size: 1.25rem;
  font-weight: 600;
}

.body-text-med-weight {
  font-weight: 600;
}

.caption {
  font-size: 0.88rem; /* 14px */
}

.caption-semi {
  font-size: 0.88rem;
  font-weight: 900;
}

.text-small {
  font-size: 0.63rem; /* 10px */
  text-transform: uppercase;
}
