::-webkit-scrollbar {
  background: #6a6a6a;
  width: 12px !important;
  border: solid 6px transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border: solid 6px transparent;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}
