@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&family=Rasa:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

/* css vars should be replaced with design tokens */
:root {
  --theme-body-bgColor: rgb(249, 239, 239);
  --theme-font-baseColor: #1e1e1e;
  --theme-font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  --theme-line-height: 1;
}

#root {
  width: 100%;
  height: 100%;
}

html {
  width: 100%; /* --> Make sure every container has width/height properties defined */
  height: 100%;
  font-size: 16px; /* --> application default font size - the ONLY place where font size is defined */
}

/* All values apply always unless specified differently in a specific component design */
body {
  width: 100%;
  height: 100%;
  background-color: var(--theme-body-bgColor);
  color: var(--theme-font-baseColor); /* default color for texts and icons */
  font-size: 1rem; /* --> Relative: uses the font size from the <html> */
  font-family: var(--theme-font-family);
  line-height: var(--theme-line-height);
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: #292128;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }
}

.styledScrollbar::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.styledScrollbar::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
}

.styledScrollbar::-webkit-scrollbar-thumb {
  background-color: #debdd9;
  border-radius: 8px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

img {
  max-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 12px;
  line-height: 12px;
}

.auth0 input::placeholder {
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: rgba(20, 12, 64, 1);
}

.recharts-cartesian-axis-tick-line text {
  color: #fff;
}

.recharts-cartesian-axis-tick-line {
  color: #fff;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#menu-industry .MuiList-root,
#menu-timeZone .MuiList-root {
  border-radius: 8px;
  background: #4d444b;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
}

#menu-industry.MuiList-root .MuiTypography-root,
#menu-timeZone .MuiList-root .MuiTypography-root {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
