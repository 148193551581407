.text-background {
  fill: #31262f;
  stroke: #e9e0e4;
  paint-order: stroke;
  stroke-width: 1px;
}

.active {
  fill: rgba(19, 16, 19, 1);
  stroke: #e9e0e4;
  paint-order: stroke;
  stroke-width: 2px;
}

.available {
  stroke: #e9e0e4;
  paint-order: stroke;
  stroke-width: 1px;
}

.inactive {
  fill: transparent;
  stroke: rgba(230, 225, 229, 0.12);
  paint-order: stroke;
  stroke-width: 2px;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #7c7579;
}

#chart {
  margin: 0 auto 24px;
}

.chunk {
  stroke-width: 2px; /* blurred lines width */
  stroke: #403e41;
}
